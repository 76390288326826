import React from 'react';
import Container from 'react-bootstrap/Container';
import Seo from '@/components/SEO';
import Root from '@/layouts/RootPolicy';

import '@/assets/css/meudoutor.scss';

const TermosMeuDoutor = () => (
  <>
    <Seo
      title="Termos de uso meu doutor"
      description="MeuDoutor da Simples Dental. Entenda os detalhes e requisitos para utilizar nossa plataforma de agendamento online e gerenciamento de pacientes, otimizando a gestão da sua clínica odontológica."
    />
    <Root>
      <Container>
        <div className="meudoutor-termos">
          <article dir="ltr">
            <h1>TERMOS E CONDIÇÕES DE USO PLATAFORMA MEU DOUTOR</h1>

            <div className="intro">
              <p>
                Pelos presentes Termos e Condições da Uso da Plataforma Meu Doutor, fornecida por{' '}
                <strong>SIMPLES DENTAL SOFTWARE SA</strong>, pessoa jurídica de direito privado,
                inscrita no CNPJ sob nº 17.607.744/0001-71, com sede na Rua Senador Carlos Gomes de
                Oliveira, 800 - Galpão 04 - Distrito Industrial, São José - SC, 88104-785, doravante
                “<strong>SIMPLES DENTAL</strong>” a pessoa física ou jurídica contratante, paciente
                de uma clínica cliente da SIMPLES DENTAL, doravante designada(a) “
                <strong>USUÁRIO</strong>”, neste ato, portador(a) dos documentos informados à
                SIMPLES DENTAL, devidamente cadastrado no banco de dados da SIMPLES DENTAL, por meio
                do login e senha (“Usuário”), declara integral ciência e concordância quanto aos
                seguintes itens:
              </p>

              <strong>CLÁUSULA 1ª – DAS DEFINIÇÕES</strong>
              <p>1.1. Aceite: tem seu significado definido na Cláusula 3.1.</p>
              <p>
                1.3. Cadastro: Significa a inclusão dos dados do Usuário, solicitados pelo cliente
                da SIMPLES DENTAL, na Plataforma SIMPLES DENTAL para sua utilização conforme estes
                Termos de Uso.
              </p>
              <p>1.4. Conta: tem seu significado definido na Cláusula 3.2.</p>
              <p>
                1.5. Dados de Pacientes: significam os dados dos Pacientes inseridos na área
                Cadastro de Pacientes da Simples Dental.
              </p>
              <p>1.6. Pacientes: significam os pacientes da clínica ou consultório odontológico.</p>
              <p>1.7. Prazo: tem seu significado definido na Cláusula 9.</p>
              <p>
                1.11. Política de Privacidade: significa a Política de Privacidade para a utilização
                da PLATAFORMA MEU DOUTOR, disponível através do link Política de privacidade.
              </p>
              <p>
                1.13. Plataforma SIMPLES DENTAL: significa a plataforma da SIMPLES DENTAL
                disponibilizada no domínio https://www.simplesdental.com/.Tal definição inclui
                software e outras formas de propriedade intelectual da SIMPLES DENTAL.
              </p>
              <p>1.14. Usuário: conforme definido no preâmbulo destes Termos de Uso.</p>

              <strong>CLÁUSULA 2ª - DO OBJETO</strong>
              <p>
                2.1. Estes Termos de Uso regulam os termos e condições aplicáveis à utilização, pelo
                Usuário, da PLATAFORMA MEU DOUTOR ferramenta que auxilia a relação entre o
                dentista/consultório odontológico e seus pacientes (Usuário).
              </p>
              <p>
                2.2. O Usuário reconhece que a PLATAFORMA MEU DOUTOR é de exclusiva propriedade e
                titularidade da SIMPLES DENTAL, inclusive, mas sem limitação, todos e quaisquer
                direitos de propriedade intelectual ou proprietários relacionados a este, sendo que
                o direito de acesso e uso do mesmo concedido pela SIMPLES DENTAL ao Usuário é
                restrito ao Usuário, não podendo ser compartilhado ou transmitido a terceiros.
                Qualquer uso da PLATAFORMA MEU DOUTOR não expressamente previsto nestes Termos de
                Uso é proibido e sujeita o Usuário às penalidades aqui estabelecidas, sem prejuízo
                das perdas e danos cabíveis e consequências legais aplicáveis.
              </p>

              <strong>
                CLÁUSULA 3ª – DA VINCULAÇÃO E ACEITE DOS TERMOS DE USO E DA POLÍTICA DE PRIVACIDADE
              </strong>
              <p>
                3.1. O Usuário concorda que os termos e condições destes Termos de Uso e da Política
                de Privacidade serão aplicáveis e vincularão o Usuário a partir do momento em que
                esse aceitar os referidos documentos (“Aceite” ou “Aceitar”) após o término de seu
                cadastro na PLATAFORMA MEU DOUTOR. O Usuário declara que: (i) está ciente de que
                estes Termos de Uso e a Política de Privacidade correspondem a um contrato
                vinculante ao Usuário, (ii) que o Aceite desses é manifestado por uma pessoa com
                poderes para tanto, sendo responsabilidade exclusiva do Usuário aferir e se
                assegurar que a pessoa que forneceu Aceite aos Termos de Uso e à Política de
                Privacidade tem poderes para executar tal ato; (iii) está de acordo com a forma e
                método de assinatura e Aceite a estes Termos de Uso e à Política de Privacidade,
                sendo esta uma forma válida e eficaz para vincular o Usuário aos seus termos e
                obrigações.
              </p>
              <p>
                3.1.1. Caso a SIMPLES DENTAL verifique, a seu exclusivo critério, que o Usuário (i)
                concorre, direta ou indiretamente, com a SIMPLES DENTAL e/ou com a Plataforma
                SIMPLES DENTAL; (ii) pode colocar em risco a utilização da PLATAFORMA por outros
                Usuários; (iii) viole as condições desses Termos de Uso ou da Política de
                Privacidade; (iv) adote qualquer outra conduta que seja incompatível com o uso
                regular da PLATAFORMA, a exclusivo critério da SIMPLES DENTAL, poderá a SIMPLES
                DENTAL bloquear o acesso do Usuário à PLATAFORMA MEU DOUTOR e consequentemente
                rescindir estes Termos de Uso, sem que sejam devidas quaisquer compensações pela
                SIMPLES DENTAL.
              </p>
              <p>
                3.1.2. O Usuário reconhece que as operações de Aceite destes Termos de Uso ou da
                Política de Privacidade serão registradas nos bancos de dados da SIMPLES DENTAL,
                juntamente com a data e hora em que o Aceite foi manifestado pelo Usuário, podendo
                tal informação ser utilizada como prova do Aceite do Usuário, independentemente de
                outra formalidade.
              </p>
              <p>
                3.2. O Usuário está ciente que qualquer uso da PLATAFORMA MEU DOUTOR é sujeito a
                estes Termos de Uso e à Política de Privacidade, sendo que o Usuário deverá ler e
                Aceitar ambos os documentos antes de iniciar o uso de sua conta de acesso à
                Plataforma MEU DOUTOR(“Conta”).
              </p>

              <strong>CLÁUSULA 4ª - DA UTILIZAÇÃO DA PLATAFORMA MEU DOUTOR SIMPLES DENTAL</strong>
              <p>
                4.1. O Usuário deverá criar a sua Conta por meio do preenchimento de Login com o
                código de paciente fornecido pela clínica e seu CPF. O Usuário é inteiramente e
                exclusivamente responsável pelas informações prestadas no Cadastro.
              </p>
              <p>
                4.1.1. Ao realizar o cadastro, o usuário concorda em vincular todos os dados e
                informações previamente fornecidas ao profissional de saúde por ele selecionado, que
                por sua vez utiliza o software SIMPLES DENTAL para utilização da PLATAFORMA MEU
                DOUTOR, observada a Política de Privacidade, incluindo documentação de suporte das
                informações.
              </p>
              <p>
                4.3. O Usuário concorda que a SIMPLES DENTAL apenas oferece ao Usuário mecanismo
                auxiliar de controle, &nbsp;confirmação ou cancelamento de agendamento e comunicação
                com o profissional odontológico que lhe atende, dessa forma será responsabilidade
                exclusiva do Usuário verificar: (i) se toda a informação inserida está correta; e se
                (ii) o conteúdo das comunicações enviadas aos Pacientes e à clínica,
                independentemente da forma empregada, está adequado às leis e regulamentos
                aplicáveis.
              </p>
              <p>
                4.4. A SIMPLES DENTAL não se responsabiliza por danos ou prejuízos ocasionados ao
                Usuário que sejam decorrentes da interrupção de acesso, por qualquer motivo, à
                PLATAFORMA MEU DOUTOR, sendo responsabilidade do Usuário garantir um meio
                alternativo de contato com o seu profissional odontológico. Sem limitar o disposto
                acima, a SIMPLES DENTAL também não será responsável em situações nas quais houve
                falha nos sistemas de transmissão ou de roteamento de dados via internet e/ou falhas
                por interferências ou aplicativos de terceiros (incluindo, mas sem limitação a
                atuação de hackers, falha de provedor de conexão, falha do gateway de pagamento ou
                de instituição bancária, falha nos serviços de armazenamento do provedor terceiro
                usado para hospedagem, entre outros do tipo). Durante o Prazo, a SIMPLES DENTAL
                envidará seus melhores esforços para tentar informar imediatamente ao Usuário se
                houver qualquer problema de conexão ou outra falha que de qualquer forma impacte os
                Usuários da PLATAFORMA MEU DOUTOR.
              </p>

              <strong>CLÁUSULA 7ª - DA LICENÇA</strong>
              <p>
                7.1. Sujeito aos termos e condições destes Termos de Uso, a SIMPLES DENTAL concede
                ao Usuário uma licença, não exclusiva, limitada e intransferível para uso exclusivo
                pelo Usuário da PLATAFORMA MEU DOUTOR em um ou mais e/ou dispositivos móveis de
                titularidade ou posse legítima do Usuário como ferramenta auxiliar de gestão,
                sujeito aos Termos de Uso.
              </p>
              <p>
                7.2. O Usuário concorda que a licença concedida na Cláusula 7.1 acima, não contempla
                ou autoriza o Usuário a usar a PLATAFORMA MEU DOUTOR para os seguintes propósitos:
              </p>
              <p>
                (i) fazer, direta ou indiretamente, engenharia reversa, reproduzir, traduzir,
                decompilar, desmontar, realizar atos que visem extrair código fonte, atualizar,
                rever ou melhorar o software ou qualquer parte deste ou envolver terceiros para
                quaisquer desses objetivos.
              </p>
              <p>
                (ii) explorar a SIMPLES DENTAL, no todo ou em parte, para prestar ou intermediar os
                Serviços para qualquer outra pessoa ou entidade que não seja o Usuário, com ou sem
                fim comercial.
              </p>
              <p>
                (iii) usar qualquer software de terceiro que interfira ou interaja com a PLATAFORMA
                MEU DOUTOR, a fim de otimizar o desempenho dos mesmos ou para coletar dados e
                informações de Usuários, sendo certo, no entanto, que a PLATAFORMA MEU DOUTOR poderá
                utilizar interfaces de terceiros, sempre que cabível e a seu exclusivo critério.
              </p>
              <p>
                (iv) usar qualquer software de terceiro que interfira ou interaja com a PLATAFORMA
                MEU DOUTOR, a fim de otimizar o desempenho dos mesmos ou para coletar dados e
                informações de Usuários, sendo certo, no entanto, que a PLATAFORMA MEU DOUTOR poderá
                utilizar interfaces de terceiros, sempre que cabível.
              </p>
              <p>
                (v) usar a PLATAFORMA MEU DOUTOR ou qualquer parte dessa para criar novos
                aplicativos, módulos, produtos ou serviços.
              </p>
              <p>
                (vi) viabilizar, criar ou manter qualquer conexão não autorizada com a PLATAFORMA
                MEU DOUTOR, incluindo, mas sem limitação, qualquer conexão que utilize programas ou
                ferramentas não autorizadas pela SIMPLES DENTAL.
              </p>
              <p>
                (vii) vender (para ganho, lucro ou qualquer outro fim), locar, emprestar, alugar,
                penhorar, usar como garantia, onerar, hipotecar, distribuir, ceder, transferir,
                exportar, melhorar, alterar, editar, criar trabalhos derivados ou conceder uma
                sublicença de qualquer elemento da PLATAFORMA MEU DOUTOR ou de qualquer documentação
                relacionada.
              </p>
              <p>
                (viii) usar ou permitir o uso, direta ou indiretamente, da propriedade intelectual
                da PLATAFORMA MEU DOUTOR, bem como da PLATAFORMA MEU DOUTOR em si ou qualquer
                documentação de suporte para finalidade diversa daquelas autorizadas pela SIMPLES
                DENTAL.
              </p>
              <p>
                (ix) invadir ou tentar invadir a privacidade de outros Usuários, buscando acesso à
                senha, dados privados e Dados de Pacientes, bem como modificar arquivos, copiar, ou
                assumir, sem autorização, a identidade de outro assinante, ou desenvolver programas
                de acesso não autorizado a computadores, arquivos, programas e dados residentes na
                rede, bem como fornecer Dados de Pacientes ou dados cadastrais falsos.
              </p>
              <p>
                7.3. A SIMPLES DENTAL declara que é proprietária da PLATAFORMA MEU DOUTOR objeto
                destes Termos de Uso, e que está autorizada a licenciar seu uso, bem como, que a
                licença aqui pactuada não infringe qualquer patente, direito autoral, segredo
                industrial ou quaisquer outros direitos de terceiros.
              </p>

              <strong>CLÁUSULA 8ª – DO BLOQUEIO DA CONTA</strong>
              <p>
                8.1. A SIMPLES DENTAL poderá, a seu exclusivo critério, sem prévia notificação ao
                Usuário, bloquear a Conta caso o Usuário (i) atue da forma prevista nas Cláusulas
                3.1.1., 5.1. e 7.2.; (i; ou (ii) infrinja no todo ou em parte, direta ou
                indiretamente, os Termos de Uso, conforme determinação da SIMPLES DENTAL.
              </p>
              <p>
                8.1.1. O Usuário poderá solicitar o desbloqueio de sua Conta por meio do e-mail
                contato@simplesdental.com. A SIMPLES DENTAL poderá, a seu exclusivo critério e
                levando em consideração as informações enviadas pelo Usuário desbloquear a Conta.
              </p>
              <p>
                8.1.2. Conforme previsto na Política de Privacidade, no caso de bloqueio da Conta a
                SIMPLES DENTAL manterá todos os dados cadastrados pelo Usuário na Plataforma SIMPLES
                DENTAL , incluindo os Dados de Pacientes, por um período de 6 (seis) meses contado
                da data de bloqueio da Conta, após o qual os dados cadastrados pelo Usuário poderão
                ser eliminados e excluídos pela SIMPLES DENTAL. Em qualquer tempo, o Usuário também
                poderá solicitar a exclusão de dados pessoais do Usuário e de seus Pacientes por
                meio do e-mail contato@simplesdental.com.
              </p>

              <strong>CLÁUSULA 9ª – DO PRAZO</strong>
              <p>
                9.1. Os presentes Termos de Uso será válido por prazo indeterminado e será aplicável
                para prestação de todos os serviços contratados com o uso da Plataforma SIMPLES
                DENTAL (“Prazo”).
              </p>

              <strong>CLÁUSULA 10ª – DO CANCELAMENTO DA LICENÇA DE USO DO USUÁRIO</strong>
              <p>
                10.1. O Usuário poderá cancelar sua assinatura de uso da PLATAFORMA MEU DOUTOR a
                qualquer momento, realizando a desinstalação do aplicativo. Em caso de cancelamento
                pela clínica cliente da SIMPLES DENTAL do seu plano pago, automaticamente o usuário
                perderá a atualização das informações no aplicativo, restando somente o histórico
                para consulta. Em nenhuma hipótese haverá ressarcimento pela SIMPLES DENTAL de
                valores pagos pelo Usuário em virtude de cancelamento de assinatura previamente ao
                término do prazo contratado para uso da Plataforma SIMPLES DENTAL, independentemente
                do motivo do cancelamento.
              </p>
              <p>
                10.2. A SIMPLES DENTAL poderá a seu critério e independentemente de notificação
                prévia cancelar a assinatura do Usuário caso (i) verifique nova ocorrência de
                quaisquer das violações dispostas na Cláusula 8.1 acima, e/ou (ii) vislumbre a
                critério exclusivo da SIMPLES DENTAL qualquer falsidade, incorreção,
                incompatibilidade, invalidade ou inatividade/baixa junto aos órgãos públicos
                emissores, dos dados fornecidos pelo Usuário no Cadastro. Neste caso,
                automaticamente o usuário perderá a atualização das informações no aplicativo,
                restando somente o histórico para visualização.
              </p>

              <strong>CLÁUSULA 11ª – DA INDENIZAÇÃO</strong>
              <p>
                11.1. O Usuário concorda em defender, indenizar e manter indene a SIMPLES DENTAL e
                suas afiliadas, diretores, empregados e agentes, integralmente de e contra quaisquer
                encargos, ações ou demandas, incluindo, mas não limitado a, honorários advocatícios
                razoáveis, resultantes: (i) da eventual utilização indevida da PLATAFORMA MEU
                DOUTOR; (ii) da violação das condições ora pactuadas, ou ainda (iv) da inobservância
                das leis e regulamentos aplicáveis.
              </p>
              <p>
                11.1.1. O Usuário é o único e exclusivo responsável pelo conteúdo das comunicações
                enviadas às clínicas e aos pacientes por meio da Plataforma SIMPLES DENTAL,
                obrigando-se a garantir que os conteúdos trafegados não violarão e nem causarão
                exposição indevida ou constrangimento dos destinatários, respondendo ainda por seu
                conteúdo em juízo ou fora dele, e eximindo a SIMPLES DENTAL em caráter incondicional
                de quaisquer responsabilidades por quaisquer reclamações de terceiros, se obrigando
                ainda a indenizar a SIMPLES DENTAL nos termos desta Clausula 11.
              </p>
              <p>
                11.2. Em nenhum caso a SIMPLES DENTAL será responsável por danos ou qualquer
                prejuízo direto ou indireto incorrido pelo Usuário ou qualquer outro terceiro
                decorrente da SIMPLES DENTAL, incluindo, sem limitação, prejuízos por perda de
                lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não
                continuidade do negócio ou qualquer outro prejuízo ou perda comercial.
              </p>

              <strong>CLÁUSULA 12ª – DA LIMITAÇÃO DE RESPONSABILIDADE</strong>
              <p>
                12.1. Sem limitar outras disposições destes Termos de Uso, a SIMPLES DENTAL não
                responderá, em nenhuma hipótese, ainda que em caráter solidário ou subsidiário:
              </p>
              <p>
                (a) Pela autenticidade, validade e precisão dos dados fornecidos pelo Usuário e/ou
                informados pelos Pacientes, conforme previsto na Cláusula 4.4 acima, competindo ao
                Usuário verificar, por conta própria, se tais informações são verdadeiras e
                completas antes de tomar qualquer decisão baseada nelas;
              </p>
              <p>
                (b) Pelos serviços ou produtos oferecidos na PLATAFORMA MEU DOUTOR por anunciantes
                ou quaisquer terceiros, inclusive no que diz respeito a sua disponibilidade,
                qualidade, quantidade, características essenciais, ofertas, preços, formas de
                pagamento ou quaisquer outros elementos a esses referentes;
              </p>
              <p>
                (c) Por eventuais prejuízos sofridos pelo Usuário em razão da tomada de decisões com
                base nas informações disponibilizadas na PLATAFORMA MEU DOUTOR;
              </p>
              <p>
                (d) Pelo envio indevido de qualquer comunicação, incluindo sem limitar e-mail e/ou
                SMS para números ou e-mails cadastrados incorretamente.
              </p>
              <p>
                12.2. Sem prejuízo a outras disposições destes Termos de Uso, a SIMPLES DENTAL não
                garante que: (a) as funções contidas na PLATAFORMA MEU DOUTOR atendem às
                necessidades do Usuário; (b) a operação da PLATAFORMA MEU DOUTOR será ininterrupta
                ou livre de erros, (c) qualquer funcionalidade da PLATAFORMA MEU DOUTOR permanecerá
                disponível; (d) os defeitos na PLATAFORMA MEU DOUTOR serão corrigidos ou que a
                PLATAFORMA MEU DOUTOR será compatível ou funcionará com qualquer outro software,
                aplicações ou serviços de terceiros.
              </p>

              <strong>CLÁUSULA 13ª – MODIFICAÇÕES</strong>
              <p>
                13.1. A SIMPLES DENTAL poderá, a qualquer tempo e independente de notificação
                prévia, alterar parte ou totalmente estes Termos de Uso, ao seu exclusivo critério,
                incluindo sem limitar suas regras de participação, regras de pagamento, regras de
                acesso e cadastro, bem como as condições, serviços atrelados e custos relacionados
                aos Planos oferecidos aos Usuários, sendo que quaisquer modificações serão
                informadas ao Usuário. O uso contínuo da PLATAFORMA MEU DOUTOR após essa notificação
                implicará no aceite tácito de dos Termos de Uso alterados pela SIMPLES DENTAL.
              </p>

              <strong>CLÁUSULA 14ª – DO NÍVEL DE SERVIÇO</strong>
              <p>
                14.1. A SIMPLES DENTAL empreenderá esforços comercialmente razoáveis para tornar a
                PLATAFORMA MEU DOUTOR disponível, no mínimo, 99,6% (noventa e nove vírgula seis por
                cento) durante cada Ano de Serviços.
              </p>
              <p>
                14.1.1. Por Ano de Serviço entenda-se os 365 dias precedentes à data de uma
                reivindicação relacionada ao nível de serviço. Se o Usuário estiver se utilizando da
                PLATAFORMA MEU DOUTOR durante período inferior a 365 dias, o Ano de Serviço que lhe
                corresponde será, ainda assim, considerado como os 365 dias precedentes; no entanto,
                os dias anteriores a seu uso dos serviços serão considerados como de 100% de
                disponibilidade. Os períodos de inatividade operacional que ocorrerem antes de uma
                reivindicação bem sucedida de Crédito de Serviço não poderão ser usados para efeito
                de reivindicações futuras.
              </p>
              <p>
                14.2. O Compromisso de nível de serviço não se aplica às circunstâncias de
                indisponibilidade que resultem (i) de uma interrupção do fornecimento de energia
                elétrica ou paradas emergenciais não superiores a 2 (duas) horas ou que ocorram no
                período das 24:00h até às 6:00h (horário de Brasília); (ii) forem causadas por
                fatores que fujam ao cabível controle da SIMPLES DENTAL, inclusive casos de força
                maior ou de acesso à Internet e problemas correlatos; (iii) resultem de quaisquer
                atos ou omissões do Usuário ou de terceiros; (iv) resultem do equipamento, software
                ou outras tecnologias que o Usuário usar e/ou do equipamento que impeçam o acesso
                regular à PLATAFORMA MEU DOUTOR; (v) resultem de falhas de instâncias individuais
                não atribuíveis à indisponibilidade do Usuário; (vi) resultem de alterações
                realizadas na forma de acesso a informações financeiras e/ou de Conta do Usuário
                pelas instituições financeiras; (vii) resultem de práticas de gerenciamento da rede
                que possam afetar sua qualidade.
              </p>

              <strong>CLÁUSULA 15ª – DAS DISPOSIÇÕES GERAIS</strong>
              <p>
                15.1. O Usuário autoriza a SIMPLES DENTAL a divulgar a contratação dos serviços da
                SIMPLES DENTAL em caráter publicitário, podendo mencionar ou divulgar ao mercado que
                o Usuário é usuário da PLATAFORMA MEU DOUTOR.
              </p>
              <p>
                15.2. Estes Termos de Uso, em conjunto com seus anexos e com a Política de
                Privacidade representam o integral entendimento entre as Partes e substitui toda e
                qualquer proposta ou documento anterior que tenha sido presentado pela SIMPLES
                DENTAL, sobre os quais tem preferência.
              </p>
              <p>
                15.3. As Partes se comprometem a zelar pelo bom desempenho da relação comercial,
                pela função social e pelo equilíbrio econômico-financeiro destes Termos de Uso,
                cooperando entre si para o cumprimento de suas obrigações, ficando ajustado que os
                casos omissos serão resolvidos por entendimento direto entre as Partes.
              </p>
              <p>
                15.4. A nulidade ou inexequibilidade de qualquer cláusula destes Termos de Uso não
                deverá afetar as demais cláusulas destes Termos de Uso, que permanecerá válido e em
                pleno vigor em relação a todas as demais cláusulas e condições.
              </p>
              <p>
                15.5. O não exercício ou o atraso no exercício, por qualquer das Partes, dos
                direitos a essas respectivamente conferidos conforme previsto nestes Termos de Uso,
                não será interpretado como renúncia em relação a tal direito. Toda e qualquer
                renúncia aos direitos estabelecidos nestes Termos de Uso somente será válida quando
                entregue por escrito e assinada pela Parte renunciante.
              </p>
              <p>
                15.6. Cada Parte será exclusiva e individualmente responsável, de acordo com a
                legislação aplicável, por quaisquer tributos devidos em decorrência das obrigações
                previstas nestes Termos de Uso.
              </p>
              <p>
                15.7. O Usuário declara, sob as penas da lei, que o Aceite destes Termos de Uso é
                realizado por pessoas que são seus procuradores/representantes legais, devidamente
                qualificados e constituídos na forma de seus respectivos contratos ou estatutos
                sociais, com poderes para assumir as obrigações ora pactuadas.
              </p>
              <p>
                15.8. Estes Termos de Uso não geram nenhuma relação de sociedade, de mandato ou
                relação de trabalho entre o Usuário e a SIMPLES DENTAL, nem qualquer compromisso de
                lucratividade e/ou de resultado.
              </p>
              <p>
                15.9. As obrigações, direitos e deveres assumidos nestes Termos de Uso não poderão
                ser cedidos pelo Usuário sem o prévio e expresso consentimento por escrito da
                SIMPLES DENTAL.
              </p>
              <p>
                15.10. As obrigações, direitos e deveres assumidos nestes Termos de Uso poderão ser
                cedidos pela SIMPLES DENTAL a qualquer outra empresa a seu critério.
              </p>

              <strong>CLÁUSULA 16ª - LEI E FORO APLICÁVEIS</strong>
              <p>
                16.1. Estes Termos de Uso serão interpretados exclusivamente segundo as leis do
                Brasil.
              </p>
              <p>
                16.2. Fica estabelecido o Foro da Comarca de Florianópolis, Estado de Santa
                Catarina, como o único competente para dirimir qualquer litígio resultante destes
                Termos de Uso ou da Política de Privacidade.
              </p>
            </div>
          </article>
        </div>
      </Container>
    </Root>
  </>
);

export default TermosMeuDoutor;
