import React, { ReactNode } from 'react';
import clsx from 'clsx';
import ChatProvider from '@/contexts/chat';
import { Footer, Menu } from '@/layouts';

import * as styles from './policy.module.scss';

export interface RootPolicyProps {
  children: ReactNode;
}

const RootPolicy = ({ children }: RootPolicyProps) => (
  <ChatProvider>
    <header className={clsx(styles.header)}>
      <Menu fixed />
    </header>
    {children}
    <Footer />
  </ChatProvider>
);

export default RootPolicy;
